
import { store } from '@/store/store';
import { defineComponent } from 'vue';
import { STATE} from '@/constants';
import { SponsorInterface, SPONSOR_TYPES, SPONSOR_TYPES_VALUES } from '@/services/sponsors';
import { TWindowSize } from '@/store/state';
import { sponsorTypeEnumToDisplayName } from '@/utils.names';
import CSS from 'csstype';
import { defaultSponsorDef } from '@/utils';

export default defineComponent({
    name: 'TheSponsorsCarousel',
    data() {
        return {
            SPONSOR_TYPE: SPONSOR_TYPES,
            activeSlide: 0,
            activeSlideExpanded: false,
            xDown: 0,
            yDown: 0
        }
    },
    props: {
        sponsors: {
            type: Array as () => SponsorInterface[],
            required: true
        }
    },
    computed: {
        isLoading(): boolean {
            return store.state.sponsorsRequestStatus.status === STATE.LOADING;
        },
        contentLength(): number {
            return Object.keys(this.sponsors).length;
        },
        windowSize(): TWindowSize {
            return store.state.windowSize
        },
        viewportHeight(): CSS.Properties {
            return {
                'height': `calc(${this.windowSize.height}px - 4em)`
            } as CSS.Properties;
        },
        numCards() :number {
            return this.windowSize.width > 1200?
                5:
                this.windowSize.width > 800?
                    3:
                    1;
        },
        sponsorCardStyle(): CSS.Properties[] {
            return this.sponsors.map(sponsor => {
                const type = sponsor.sponsorType;
                let color = sponsor.color ?? '#254d6d';
                switch(type) {
                    case SPONSOR_TYPES.NONE: break;
                    case SPONSOR_TYPES.BRONZE: color = '#704A07'; break;
                    case SPONSOR_TYPES.PLATINUM: color = '#D3D6D8'; break;
                    case SPONSOR_TYPES.GOLD: color = '#D3AF37'; break;
                    case SPONSOR_TYPES.SILVER: color = '#8C8D91'; break;
                }
                return {
                    'border-color': color
                } as CSS.Properties;
            });
        },
        logo(): string[] {
            const dflt = defaultSponsorDef().logo;
            return this.sponsors.map(sponsor => {
                const sponsorLogo = sponsor.logo ?? '';
                return sponsorLogo === ''? dflt: sponsorLogo;
            });
        }
    },
    methods: {
        focusCarousel(){
            (this.$refs.carousel as HTMLElement)?.focus();
        },
        changeActiveIndex(ev: KeyboardEvent | MouseEvent, diff?: number) {
            if(diff) {
                this.activeSlide = Math.min(
                    this.contentLength - 1,
                    Math.max(this.activeSlide + diff, 0)
                );
            }
            else if((ev as KeyboardEvent)?.key === 'ArrowLeft') {
                this.activeSlide = Math.max(this.activeSlide - 1, 0);
            }
            else if((ev as KeyboardEvent)?.key === 'ArrowRight') {
                this.activeSlide = Math.min(
                    this.activeSlide + 1,
                    this.contentLength - 1
                );
            }

            this.activeSlideExpanded = false;
        },
        handleTouchStart(evt: TouchEvent) {
            this.xDown = evt.touches[0].clientX;
            this.yDown = evt.touches[0].clientY;
        },
        handleTouchMove(evt: TouchEvent) {
            if ( ! this.xDown || ! this.yDown ) return;
            
            const xDiff = this.xDown - evt.touches[0].clientX;
            const yDiff = this.yDown - evt.touches[0].clientY;
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    this.activeSlide = Math.min(
                        this.activeSlide + 1,
                        this.contentLength - 1
                    );
                }
                else this.activeSlide = Math.max(this.activeSlide - 1, 0);
            }
            this.xDown = 0;
            this.yDown = 0;
        },
        slideVisible(index: number) {
            if(index === this.activeSlide) return true;
            let spanRight = (this.numCards - 1) / 2;
            let spanLeft = (this.numCards - 1) / 2;
            if(this.activeSlide + spanRight > this.contentLength - 1) {
                spanLeft += this.activeSlide + spanRight - (this.contentLength - 1);
            }
            if(this.activeSlide - spanLeft < 0) {
                spanRight += 0 - (this.activeSlide - spanLeft);
            }
            return index <= this.activeSlide + spanRight && index >= this.activeSlide - spanLeft;
        },
        contentLink(sponsor: SponsorInterface): string {
            return `/sponsors#${encodeURI(sponsor.name)}`;
        },
        sponsorType(name: SPONSOR_TYPES_VALUES) {
            if(name === SPONSOR_TYPES.NONE) return '';
            return sponsorTypeEnumToDisplayName(name);
        }
    },
});
