
import { defineComponent } from 'vue';
import { NEXT_MODULE_DIR } from '@/constants';

export default defineComponent({
    name: 'NextModule',
    props: {
        direction: {
            type: Number as () => NEXT_MODULE_DIR,
            require: true
        },
        text: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        isUp(): boolean {
            return this.direction === NEXT_MODULE_DIR.UP;
        },
        isDown(): boolean {
            return this.direction === NEXT_MODULE_DIR.DOWN;
        },
        hasText(): boolean {
            return this.text !== '';
        }
    }
});
