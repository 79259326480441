
import { defineComponent } from 'vue';
import TheUpcomingGames from '@/components/TheUpcomingGames.vue';
import TheLeagueDescription from '@/components/TheLeagueDescription.vue';
import TheSponsorsCarousel from '@/components/TheSponsorsCarousel.vue';
import ContactUsContent from '@/components/ContactUsContent.vue';
import NextModule from '@/components/NextModule.vue';
import { NEXT_MODULE_DIR } from '@/constants';
import { store } from '@/store/store';
import { SponsorInterface } from '@/services/sponsors';
import { ScheduleInterface } from '@/services/series';

export default defineComponent({
    name: 'Home',
    components: {
    TheUpcomingGames,
    TheLeagueDescription,
    TheSponsorsCarousel,
    ContactUsContent,
    NextModule,
},
    data() {
        return {
            down: NEXT_MODULE_DIR.DOWN,
            up: NEXT_MODULE_DIR.UP,
            filterSetName: '__none__'
        }
    },
    computed: {
        schedule(): ScheduleInterface[] {
            return store.getters.filteredSchedule(this.filterSetName);
        },
        hasSchedule(): boolean {
            return this.schedule.length > 0;
        },
        sponsors(): SponsorInterface [] {
            return store.state.sponsors;
        },
        sortedSponsors(): SponsorInterface[] {
            return store.getters.sortedSponsors;
        },
        hasSponsors(): boolean {
            return this.sponsors.length > 0;
        },
        upcommingGamesNextModuleText(): string {
            return this.hasSponsors? 'Nos Commanditaires': 'Questions? Contactez-nous!';
        }
    },
    methods: {
        gotoModule(idx: number) {
            const nextModule = (this.$refs.root as HTMLElement).children;
            nextModule[idx]?.scrollIntoView();
        }
    }
});
