
import { EMPTY_IMAGE_SVG } from '@/constants';
import { toDataUrl } from '@/utils.resources';
import { fetchHomeImage1, fetchLogoTextNarrow, fetchLogoText } from '@/services/resources';
import { closeAllDialogsAndPopups, MUTATION } from '@/store/mutations';
import { defineComponent } from 'vue';
import { TWindowSize } from '@/store/state';
import { ACTION } from '@/store/actions';
import { STATE } from '@/constants';
import { store } from '@/store/store';

export default defineComponent({
    name: 'TheLeagueDescription',
    data() {
        return {
            homeImageLoaded: false,
            logoTextLoaded: false,
            logoNarrowLoaded: false,
            homeImg: EMPTY_IMAGE_SVG,
            logoText:EMPTY_IMAGE_SVG,
            logoNarrow: EMPTY_IMAGE_SVG
        }
    },
    computed: {
        windowSize(): TWindowSize {
            return store.state.windowSize;
        },
        viewportHeight(): {height: string} {
            return {
                'height': `calc(${this.windowSize.height}px - 4em)`
            };
        },
        descrClass(): {delayedShow: boolean} {
            return {
                delayedShow: store.state.leagueDescriptionRequestStatus.status === STATE.DONE
            }
        },
        imgClass(): {delayedShow: boolean} {
            return {
                delayedShow: this.homeImageLoaded,
            }
        },
        logoTextClass(): {delayedShow: boolean} {
            return {
                delayedShow: this.logoTextLoaded,
            }
        },
        logoNarrowClass(): {delayedShow: boolean} {
            return {
                delayedShow: this.logoNarrowLoaded,
            }
        },
        longDescr(): string {
            return store.state.leagueDescription.long;
        },
        shortDescr(): string {
            return store.state.leagueDescription.short;
        },
        signUpBtnVisible(): boolean {
            return store.getters.signUpButtonVisible;
        },
        joinTeamBtnVisible(): boolean {
            return store.getters.joinTeamButtonVisible;
        }
    },
    methods: {
        toggleJoinTeamDialog(show: boolean) {
            closeAllDialogsAndPopups(store);
            store.commit(MUTATION.SHOW_JOIN_TEAM_DIALOG, show);
        },
        toggleSignupDialog(show: boolean) {
            closeAllDialogsAndPopups(store);
            store.commit(MUTATION.SHOW_SIGNUP_DIALOG, {
                show,
                username: '',
                teams: store.getters.teamsIFavorite.map(t => t.name),
                teamCaptain: false,
                name: ''
            });
        }
    },
    async mounted() {

            // asynchronously fetch images and data and set status accordingly
            // so that everything loads smoothly

        store.dispatch(ACTION.FETCH_LEAGUE_DESCRIPTION, undefined);
        let img = await fetchHomeImage1();
        let dataUrl = await toDataUrl(img);
        if(dataUrl) {
            this.homeImg = dataUrl;
            this.homeImageLoaded = true;
        }
        else this.homeImageLoaded = false;

        img = await fetchLogoText();
        dataUrl = await toDataUrl(img);
        if(dataUrl) {
            this.logoText = dataUrl;
            this.logoTextLoaded = true;
        }
        else this.logoTextLoaded = false;

        img = await fetchLogoTextNarrow();
        dataUrl = await toDataUrl(img);
        if(dataUrl) {
            this.logoNarrow = dataUrl;
            this.logoNarrowLoaded = true;
        }
        else this.logoNarrowLoaded = false;
    }
});
